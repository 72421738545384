import React from "react";
import { Box } from "@mui/material";

export default function LandingPage() {
  return (
    <Box
      justifyContent="center"
      sx={{
        display: "flex",
        mt: 11.5,
        pb: "2.5%",
        pr: "15%",
        pl: "15%",
        backgroundImage:
          "linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,.1), rgba(0,0,0,1)), url(./landingPageBg.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
      }}
    >
      <img src="./dcBanner.png" style={{ width: "96%", opacity: 0.7 }} alt="" />
    </Box>
  );
}
