import React from "react";
import {
  Box,
  Container,
  Stack,
  Typography,
  ImageListItem,
  Divider,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

function Copyright() {
  return (
    <Typography variant="body2" color="#fff">
      {"Copyright © "}
      {new Date().getFullYear()} {"Dental Cosmetica. All rights reserved."}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: "#000",
        paddingTop: "1rem",
        paddingBottom: "1rem",
        color: "#fff",
      }}
    >
      <Container maxWidth="lg">
        <Stack
          direction="row"
          useFlexGap
          flexWrap="wrap"
          spacing={{ xs: 1, sm: 8, md: 16 }}
          sx={{ justifyContent: "center" }}
        >
          <Box sx={{ p: 4 }}>
            <ImageListItem
              sx={{
                width: 225,
                height: 75,
              }}
            >
              <img src="./dclogo.png" alt="" />
            </ImageListItem>
          </Box>
          <Box sx={{ p: 5 }}>
            <Typography>
              <Button
                target="_blank"
                href="https://wa.me/916289902288"
                sx={{ color: "#fff" }}
              >
                <FontAwesomeIcon icon={faWhatsapp} size="2x" />
              </Button>
              <Button
                target="_blank"
                href="https://instagram.com/dental_cosmetica"
                sx={{ color: "#fff" }}
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </Button>
              <Button sx={{ color: "#fff" }}>
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </Button>
            </Typography>
            <Typography variant="body2">
              <b>Call / WatsApp:</b> +91 62899 02288
            </Typography>
            <Typography variant="body2">
              <b>Email:</b> contact@dentalcosmetica.in
            </Typography>
          </Box>
        </Stack>
        <Divider sx={{ bgcolor: "#fff" }} />
        <Stack
          direction="row"
          useFlexGap
          flexWrap="wrap"
          spacing={{ xs: 1, sm: 4, md: 8 }}
          sx={{ justifyContent: "center" }}
        >
          <Box sx={{ pt: 2 }}>
            <Copyright />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
