import "./App.css";
import Navbar from "./components/Navbar";
import LandingPage from "./components/LandingPage";
import MissionVision from "./components/MissionVision";
import Map from "./components/map/Map";
import Footer from "./components/Footer";
import Portfolio from "./components/Portfolio";
import AppointmentForm from "./components/AppointmentForm";

function App() {
  return (
    <>
      <Navbar />
      <LandingPage />
      <AppointmentForm />
      <MissionVision />
      <Portfolio />
      <Map />
      <Footer />
    </>
  );
}

export default App;
