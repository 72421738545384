import React, { useEffect, useRef, useState } from "react";
import "./map.css";
import { Box, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";

const MyComponent = styled("span")({
  color: "#af8f44",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  padding: 8,
  borderRadius: 4,
});

const useContainerDimensions = (myRef) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};

export default function Map() {
  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);
  return (
    <Box
      pb={{ xs: "70%", sm: "25%", md: "8%" }}
      sx={{
        backgroundImage:
          "linear-gradient(rgba(0,0,0,1), rgba(0,0,0,.3), rgba(0,0,0,1)), url(./mapBg.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
        pt: "8%",
      }}
    >
      <Stack
        direction="row"
        useFlexGap
        flexWrap="wrap"
        spacing={{ xs: 1, sm: 4, md: 8 }}
        sx={{ justifyContent: "center", mb: "6%" }}
      >
        <Typography
          color="#fff"
          fontSize={{ xs: 28, sm: 48 }}
          sx={{ fontWeight: 500 }}
        >
          Where To <MyComponent>Find Us</MyComponent>?
        </Typography>
      </Stack>
      <Stack
        direction="row"
        useFlexGap
        flexWrap="wrap"
        spacing={{ xs: 1, sm: 4, md: 8 }}
        sx={{ justifyContent: "center" }}
      >
        <Box
          sx={{
            height: 450,
            minWidth: 320,
            maxWidth: 600,
            width: "auto",
            p: 2,
          }}
        >
          <Card
            ref={componentRef}
            sx={{
              p: 2,
              height: 450,
              border: "0.5px solid #d3d3d3",
              background: "rgba(0, 0, 0, 0.7)",
              backgroundSize: "contain",
              color: "#fff",
            }}
          >
            <CardContent>
              <Typography variant="h5" component="div">
                Golf Nook
              </Typography>
              <Typography sx={{ mb: 1.5 }} variant="body2">
                14/15, Golf Club Rd, Rajendra Prasad Colony, Tollygunge,
                Kolkata, West Bengal 700033
              </Typography>
              <br />
              <Typography
                variant="body2"
                sx={{ fontSize: 16, fontWeight: 550, pb: 0.5 }}
              >
                Hours
              </Typography>
              <Typography variant="body2">
                Monday - Saturday (09:30 AM - 08:30 PM)
                <br />
                Sunday (09:30 AM - 01:30 PM)
              </Typography>
              <br />
              <Typography
                variant="body2"
                sx={{ fontSize: 16, fontWeight: 550, pb: 0.5 }}
              >
                Contact Us
              </Typography>
              <Typography variant="body2">
                <b>Call / WatsApp:</b> +91 62899 02288
              </Typography>
              <Typography variant="body2">
                <b>Email:</b> contact@dentalcosmetica.in
                <br />
                <br />
                <br />
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                sx={{ color: "#fff" }}
                target="_blank"
                size="small"
                href="https://maps.app.goo.gl/m9pUA5fGFF6TCTd96"
              >
                Learn More
              </Button>
            </CardActions>
          </Card>
        </Box>
        <Box
          sx={{
            height: 450,
            width: "auto",
            p: 2,
          }}
        >
          <Card
            sx={{
              p: 1,
              height: height,
              width: width,
              border: "0.5px solid #d3d3d3",
              background: "rgba(0, 0, 0, 0.7)",
              backgroundSize: "contain",
              color: "#fff",
            }}
          >
            <iframe
              title="Dental Cosmetica"
              class="responsive-iframe"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58965.45708506256!2d88.31243901953125!3d22.528892912746784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a027131307f5ca1%3A0x3ab2494b94ac6e0b!2sDental%20Cosmetica%20%7C%20Best%20Dental%20Clinic%20in%20Kolkata!5e0!3m2!1sen!2sus!4v1726608221937!5m2!1sen!2sus"
            />
          </Card>
        </Box>
      </Stack>
    </Box>
  );
}
