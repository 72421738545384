import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ImageListItem from "@mui/material/ImageListItem";
import Container from "@mui/material/Container";
import CloseIcon from "@mui/icons-material/Close";

const drawerWidth = 240;
const navItems = ["Home", "About Us", "Services", "Contact Us"];

function Navbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.only("xs"));

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const darkTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#000000",
      },
    },
  });

  function Item(props) {
    const { sx, ...other } = props;
    return (
      <Box
        sx={{
          ...sx,
        }}
        {...other}
      />
    );
  }

  Item.propTypes = {
    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
      ),
      PropTypes.func,
      PropTypes.object,
    ]),
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Item key="Close" sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          aria-label="delete"
          sx={{ color: "#000", fontWeight: 550, p: 4 }}
          onClick={handleDrawerToggle}
        >
          <CloseIcon />
        </IconButton>
      </Item>
      {navItems.map((item) => (
        <Item key={item} sx={{ justifyContent: "center" }}>
          <Button key={item} sx={{ color: "#000", fontWeight: 550, p: 2 }}>
            {item}
          </Button>
        </Item>
      ))}
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ThemeProvider theme={darkTheme}>
        <AppBar
          component="nav"
          sx={{
            display: { md: "flex" },
            height: 95,
          }}
        >
          <Container
            maxWidth="xl"
            sx={{
              pr: { matchesXs } ? 0 : 4,
            }}
          >
            <Toolbar
              sx={{
                pr: { matchesXs } ? 0 : 4,
              }}
            >
              <div style={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Item
                    sx={{
                      mr: 2,
                      display: { sm: "none", xs: "flex", m: 2 },
                      justifyContent: "space-between",
                    }}
                  >
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Item>

                  <Item
                    sx={{
                      display: { xs: "flex" },
                    }}
                  >
                    <ImageListItem
                      sx={{
                        width: 225,
                        height: 75,
                      }}
                    >
                      <img src="./dclogo.png" alt="" />
                    </ImageListItem>
                  </Item>

                  <Item
                    sx={{
                      display: { xs: "none", sm: "flex" },
                    }}
                  >
                    {navItems.map((item) => (
                      <Button
                        key={item}
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          textTransform: "none",
                          mr: 3,
                        }}
                      >
                        {item}
                      </Button>
                    ))}
                  </Item>
                </Box>
              </div>
            </Toolbar>
          </Container>
        </AppBar>
      </ThemeProvider>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navbar;
