import React from "react";
import { Box, Divider, Grid, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMobile } from "@fortawesome/fontawesome-free-solid";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";

const MyComponent = styled("span")({
  color: "#af8f44",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  padding: 8,
  borderRadius: 4,
});

function MyGrid(props) {
  return (
    <Grid container xs={12}>
      <Grid xs={5}>
        <Typography
          sx={{
            textAlign: "right",
            backgroundColor: props.dayBg,
          }}
        >
          {props.day} &nbsp;
        </Typography>
      </Grid>
      <Grid xs={7}>
        <Typography sx={{ textAlign: "left" }}>
          &nbsp;: &nbsp; &nbsp; &nbsp;<nobr>{props.morningTime}</nobr>
          &nbsp;&nbsp; {props.eveningTime !== "" ? "|" : ""}&nbsp;&nbsp;
          <nobr>{props.eveningTime}</nobr>
        </Typography>
      </Grid>
      {props.divider === "true" ? (
        <Grid xs={12}>
          <Divider sx={{ mt: 1, mb: 1, ml: 8, mr: 6, bgcolor: "#fff" }} />
        </Grid>
      ) : null}
    </Grid>
  );
}

export default function AppointmentForm() {
  return (
    <Box
      pb={{ xs: "70%", sm: "25%", md: "8%" }}
      sx={{
        backgroundImage:
          "linear-gradient(rgba(0,0,0,1), rgba(0,0,0,.3), rgba(0,0,0,1)), url(./scheduleAppointmentBg.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        pt: "8%",
        clear: "both",
      }}
    >
      <Stack
        direction="row"
        useFlexGap
        flexWrap="wrap"
        spacing={{ xs: 1, sm: 4, md: 8 }}
        sx={{
          justifyContent: "center",
          mb: "6%",
        }}
      >
        <Typography
          color="#fff"
          fontSize={{ xs: 28, sm: 48 }}
          sx={{ fontWeight: 500 }}
        >
          Schedule <MyComponent>Appointment</MyComponent>.
        </Typography>
      </Stack>
      <Stack
        direction="row"
        useFlexGap
        flexWrap="wrap"
        spacing={{ xs: 6, sm: 4, md: 8 }}
        sx={{ justifyContent: "center" }}
      >
        <Box
          sx={{
            height: 480,
            minWidth: 320,
            maxWidth: 600,
            width: "auto",
            p: 2,
          }}
        >
          <Card
            sx={{
              p: 2,
              minHeight: 480,
              background: "rgba(0, 0, 0, 0.7)",
              backgroundSize: "contain",
              color: "#fff",
              border: "0.5px solid #d3d3d3",
              fontWeight: 300,
            }}
          >
            <CardContent>
              <Typography
                variant="body"
                component="div"
                sx={{ textAlign: "center", mt: 4, mb: 8 }}
              >
                Take the first step to a healthier, beautiful smile — schedule
                your appointment with the Best Dental Clinic in Kolkata —
                <b style={{ whiteSpace: "nowrap" }}>Dental Cosmetica</b> today!
              </Typography>
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid xs={5}>
                  <Typography sx={{ textAlign: "right" }}>
                    <FontAwesomeIcon
                      icon={faMobile}
                      size="3x"
                      flip="horizontal"
                      shake
                      style={{ color: "#a3c9ff" }}
                    />
                    {"  "}
                    &nbsp;{" "}
                    <Tooltip
                      title="Click here to connect on WatsApp"
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      arrow
                    >
                      <a
                        target="_blank"
                        href="https://wa.me/916289902288"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          size="3x"
                          beatFade
                          style={{ color: "#24d366c4" }}
                        />
                      </a>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid xs={7}>
                  <Typography
                    sx={{ ml: 4, textAlign: "left", fontWeight: 200 }}
                  >
                    Phone / WatsApp
                    <br />
                    <b>+91 62899 02288</b>
                  </Typography>
                </Grid>
              </Grid>

              <Divider sx={{ m: 4, bgcolor: "#fff" }} />

              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid xs={5}>
                  <Typography sx={{ mr: 3, textAlign: "right" }}>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      size="3x"
                      flip="horizontal"
                      bounce
                      style={{ color: "#ff7565bd" }}
                    />
                  </Typography>
                </Grid>
                <Grid xs={7}>
                  <Typography
                    sx={{
                      ml: 4,
                      textAlign: "left",
                      fontWeight: 200,
                      wordWrap: "break-word",
                    }}
                  >
                    Email
                    <br />
                    <b>contact@dentalcosmetica.in</b>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Box
          sx={{
            height: 480,
            minWidth: 320,
            maxWidth: 600,
            width: "auto",
            p: 2,
          }}
        >
          <Card
            sx={{
              p: 2,
              minHeight: 480,
              background: "rgba(0, 0, 0, 0.7)",
              backgroundSize: "contain",
              color: "#fff",
              border: "0.5px solid #d3d3d3",
              fontWeight: 300,
            }}
          >
            <CardContent>
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid xs={12}>
                  <Typography
                    variant="h4"
                    component="div"
                    sx={{
                      textAlign: "center",
                      mt: 2,
                      mb: 4,
                    }}
                  >
                    Available On
                  </Typography>
                </Grid>
                <MyGrid
                  day="Monday"
                  morningTime="9:30 AM - 8:30 PM"
                  eveningTime=""
                  dayBg="rgba(148, 0, 211, 0.1)"
                  divider="true"
                />
                <MyGrid
                  day="Tuesday"
                  morningTime="9:30 AM - 8:30 PM"
                  eveningTime=""
                  dayBg="rgba(75, 0, 130, 0.1)"
                  divider="true"
                />
                <MyGrid
                  day="Wednesday"
                  morningTime="9:30 AM - 8:30 PM"
                  eveningTime=""
                  dayBg="rgba(0, 0, 255, 0.1)"
                  divider="true"
                />
                <MyGrid
                  day="Thursday"
                  morningTime="9:30 AM - 8:30 PM"
                  eveningTime=""
                  dayBg="rgba(0, 255, 0, 0.1)"
                  divider="true"
                />
                <MyGrid
                  day="Friday"
                  morningTime="9:30 AM - 8:30 PM"
                  eveningTime=""
                  dayBg="rgba(255, 255, 0, 0.1)"
                  divider="true"
                />
                <MyGrid
                  day="Saturday"
                  morningTime="9:30 AM - 8:30 PM"
                  eveningTime=""
                  dayBg="rgba(255, 127, 0, 0.1)"
                  divider="true"
                />
                <MyGrid
                  day="Sunday"
                  morningTime="9:30 AM - 1:30 PM"
                  eveningTime=""
                  dayBg="rgba(255, 0, 0, 0.1)"
                  divider="false"
                />
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Stack>
    </Box>
  );
}

// Form for schedule appointment

// import
/*import {
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  ThemeProvider,
  createTheme,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";*/

// constants
/* const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            color: "#fff",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(228, 219, 233, 0.25)",
            },
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(228, 219, 233, 0.25)",
              },
            },
            "&:hover:not(.Mui-focused)": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(228, 219, 233, 0.25)",
              },
            },
          },
          "& .MuiInputLabel-outlined": {
            color: "#fff",
            "&.Mui-focused": {
              color: "#fff",
            },
          },
        },
      },
    },
  },
});
const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  }; */

// component
/* <Box
          component="form"
          sx={{
            height: 480,
            minWidth: 375,
            width: "33%",
          }}
          noValidate
          autoComplete="off"
        >
          <Card
            sx={{
              p: 2,
              minHeight: 480,
              border: "3px solid #af8f44",
              backgroundImage: "url(./appointmentBg.jpg)",
              backgroundSize: "contain",
            }}
          >
            <ThemeProvider theme={theme}>
              <CardContent>
                <TextField
                  id="patientName"
                  label="Patient Name"
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={{
                    mb: 4,
                  }}
                />
                <TextField
                  id="patientAge"
                  label="Patient Age"
                  type="number"
                  size="small"
                  fullWidth
                  sx={{ mb: 4 }}
                />
                <TextField
                  fullWidth
                  size="small"
                  label="Phone Number"
                  type="number"
                  variant="outlined"
                  name="phone"
                  sx={{ mb: 4 }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ mb: 4 }}
                    label="Appointment Date"
                    slotProps={{
                      textField: { size: "small", fullWidth: true },
                      openPickerButton: {
                        color: "primary",
                      },
                    }}
                    fullWidth
                  />
                </LocalizationProvider>
                <FormControl size="small" fullWidth>
                  <InputLabel
                    id="demo-select-small-label"
                    sx={{
                      color: "#fff",
                      "&.Mui-focused": {
                        color: "#fff",
                      },
                    }}
                  >
                    Appointment Slots
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={age}
                    label="Slots"
                    onChange={handleChange}
                    sx={{
                      mb: 4,
                      color: "#fff",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(228, 219, 233, 0.25)",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(228, 219, 233, 0.25)",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(228, 219, 233, 0.25)",
                      },
                      ".MuiSvgIcon-root ": {
                        fill: "white !important",
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>4PM - 5PM</MenuItem>
                    <MenuItem value={2}>5PM - 6PM</MenuItem>
                    <MenuItem value={3}>6PM - 7PM</MenuItem>
                    <MenuItem value={4}>7PM - 8PM</MenuItem>
                    <MenuItem value={5}>8PM - 9PM</MenuItem>
                  </Select>
                </FormControl>
                <Box sx={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#af8f44",
                      color: "#fff",
                      fontWeight: 600,
                    }}
                  >
                    SUBMIT
                  </Button>
                </Box>
              </CardContent>
            </ThemeProvider>
          </Card>
        </Box> */
