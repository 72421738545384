import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Box, Stack } from "@mui/material";
import { styled } from "@mui/system";

function MyCard(props) {
  return (
    <Card
      sx={{
        maxWidth: 375,
        border: "0.5px solid #d3d3d3",
        background: "rgba(255, 255, 255, 0.2)",
      }}
    >
      <CardActionArea>
        <CardMedia component="img" width="100%" image={props.image} alt="" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography variant="body2" color="#000">
            {props.text}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

const MyComponent = styled("span")({
  color: "#af8f44",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  padding: 8,
  borderRadius: 4,
});

export default function MissionVision() {
  return (
    <Box
      pb={{ xs: "70%", sm: "25%", md: "8%" }}
      sx={{
        backgroundImage:
          "linear-gradient(rgba(0,0,0,1), rgba(0,0,0,.3), rgba(0,0,0,1)), url(./missionVisionBg.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
        pt: "8%",
      }}
    >
      <Stack
        direction="row"
        useFlexGap
        flexWrap="wrap"
        spacing={{ xs: 1, sm: 4, md: 8 }}
        sx={{ justifyContent: "center", mb: "6%" }}
      >
        <Box>
          <Typography
            color="#fff"
            fontSize={{ xs: 28, sm: 48 }}
            sx={{ fontWeight: 500 }}
          >
            Why <MyComponent>Us</MyComponent>?
          </Typography>
        </Box>
      </Stack>

      <Stack
        direction="row"
        useFlexGap
        flexWrap="wrap"
        spacing={{ xs: 1, sm: 4, md: 8 }}
        sx={{ justifyContent: "center" }}
      >
        <Box sx={{ p: 2 }}>
          <MyCard
            title="VISION"
            image="./vision.webp"
            text="Provide exceptional dental and cosmetic care with a personal touch. Ensure every patient feels valued and leaves with a confident smile."
          ></MyCard>
        </Box>
        <Box sx={{ p: 2 }}>
          <MyCard
            title="MISSION"
            image="./mission.avif"
            text="Be the premier choice for quality dental and cosmetic services. Transform lives by creating beautiful, healthy smiles."
          ></MyCard>
        </Box>
        <Box sx={{ p: 2 }}>
          <MyCard
            title="VALUES"
            image="./values.png"
            text="Patient-Centric: Prioritize patient needs and comfort. Excellence: Deliver superior care using advanced techniques."
          ></MyCard>
        </Box>
      </Stack>
    </Box>
  );
}
