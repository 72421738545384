import React from "react";
import { Box, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";

const MyComponent = styled("span")({
  color: "#af8f44",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  padding: 8,
  borderRadius: 4,
});

export default function Portfolio() {
  return (
    <Box
      pb={{ xs: "70%", sm: "25%", md: "8%" }}
      sx={{
        backgroundImage:
          "linear-gradient(rgba(0,0,0,1), rgba(0,0,0,.3), rgba(0,0,0,1)), url(./portfolioBg.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
        pt: "8%",
      }}
    >
      <Stack
        direction="row"
        useFlexGap
        flexWrap="wrap"
        spacing={{ xs: 1, sm: 4, md: 8 }}
        sx={{
          justifyContent: "center",
          mb: "6%",
        }}
      >
        <Typography
          color="#fff"
          fontSize={{ xs: 28, sm: 48 }}
          sx={{ fontWeight: 500 }}
        >
          Who <MyComponent>Are We</MyComponent>?
        </Typography>
      </Stack>
      <Stack
        direction="row"
        useFlexGap
        flexWrap="wrap"
        spacing={{ xs: 1, sm: 4, md: 8 }}
        sx={{ justifyContent: "center" }}
      >
        <Box
          sx={{
            height: 480,
            minWidth: 320,
            maxWidth: 600,
            width: "auto",
            p: 2,
          }}
        >
          <Card
            sx={{
              p: 2,
              height: 480,
              border: "0.5px solid #d3d3d3",
              background: "rgba(0, 0, 0, 0.7)",
            }}
          >
            <CardMedia
              component="img"
              alt="green iguana"
              height="360"
              image="./docPic.jpeg"
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                color="#fff"
              >
                Dr. Ayush Agarwal
              </Typography>
              <Typography variant="body2" color="#fff">
                Chief Dental Surgeon, Dental Cosmetica
              </Typography>
            </CardContent>
          </Card>
        </Box>
        <Box
          sx={{
            height: 480,
            minWidth: 320,
            maxWidth: 600,
            width: "auto",
            p: 2,
          }}
        >
          <Card
            sx={{
              p: 2,
              minHeight: 480,
              border: "0.5px solid #d3d3d3",
              background: "rgba(0, 0, 0, 0.7)",
            }}
          >
            <CardContent>
              <Typography variant="h5" component="div" color="#fff">
                About
              </Typography>
              <Typography
                sx={{ mb: 1.5, mt: 3, fontWeight: 300 }}
                variant="body2"
                color="#fff"
              >
                Dr. Ayush Agarwal completed his Bachelors in Dental Surgery in
                2024, from Kolkata under the West Bengal University Of Health
                Sciences.
                <br />
                <br />
                He is pursuing International Fellowship in Medical Cosmetology
                (Germany) , from Institute of Laser & Aesthetic Medicine
                (ILAMED).
                <br />
                <br />
                Currently, he is an Associate Dental Surgeon at Dental Care
                Clinic (Modern Dental Clinic), Howrah and Seva Bharti Trust, New
                Alipore and now runs his owns individual practice, at Dental
                Cosmetica, Tollygunge.
                <br />
                <br />
                He is adept in performing all general Dental Procedures
                including Root Canals, Extractions, Scaling, etc.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" sx={{ color: "#fff" }}>
                Learn More
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Stack>
    </Box>
  );
}
